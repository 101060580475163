// import eeuu from '../../images/eeuu.png'
// import arg from '../../images/argentina.png'
// import iconoStock from '../../images/stockDisponible.png'
// import wapp from '../../images/wapp.png'


// export default function ModalArticulo({ selectedArticulo, closeModal }) {


//     const enviarWapp = (precio) => {
//         const message = `BICICLETERIA LA FRANCESA\nArtículo: ${selectedArticulo?.Descripcion?.trim()}.\nPrecio: $${parseFloat(precio)?.toFixed(2)}`;
//         const encodedMessage = encodeURIComponent(message);
//         const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;

//         window.open(whatsappUrl, "_blank");
//     };


//     return (
//         <div className="fixed inset-0 bg-gray-800 bg-opacity-80 flex justify-center items-center z-50">
//             <div className="bg-white p-6 m-1 rounded-sm shadow-lg w-full lg:max-w-lg border border-black relative">
//                 <div className='flex justify-center items-center mb-0 -mt-5'>
//                     <p className='text-center text-md font-semibold'>Precio actualizado al: {selectedArticulo?.fupcosto}</p>
//                 </div>
//                 <div className="flex justify-center items-center">
//                     <img
//                         src={selectedArticulo?.Monedadolar?.trim() == '1' ? eeuu : arg}
//                         alt="bandera"
//                         className="w-6 h-6 mr-4"
//                     />
//                     <h2 className="text-lg text-center font-bold mr-4">{selectedArticulo?.Codigo}</h2>
//                     {selectedArticulo?.Stockactual?.trim() !== '0' && (
//                         <img src={iconoStock} alt="stock" className='w-7 h-6' />
//                     )}

//                 </div>

//                 <h2 className="text-lg text-center font-bold text-blue-700">
//                     {selectedArticulo?.Descripcion?.trim()}
//                 </h2>
//                 {
//                     selectedArticulo?.Stockactual?.trim() !== '0' ?
//                         <>
//                             <hr className="mb-0.5 border border-gray-300" />
//                             <p className='text-center font-semibold'>STOCK: {selectedArticulo?.Stockactual}</p>
//                             <hr className="mt-0.5 border border-gray-300" />
//                         </>
//                         :
//                         <hr className="mb-1 border border-gray-300" />
//                 }


//                 <p className='text-center text-red-600 font-semibold'>PRECIO PUBLICO</p>
//                 <div className='flex justify-between items-center'>
//                     <p className='font-black'>PvtaP1</p>
//                     <p className='font-black'>PvtaP2</p>
//                     <p className='font-black'>PvtaP3</p>
//                 </div>
//                 <div className='flex justify-between items-center'>
//                     <p>{parseFloat(selectedArticulo?.Prevtapub1)?.toFixed(2)}</p>
//                     <p>{parseFloat(selectedArticulo?.Prevtapub2)?.toFixed(2)}</p>
//                     <p>{parseFloat(selectedArticulo?.Prevtapub3)?.toFixed(2)}</p>
//                 </div>
//                 <div className='flex justify-between items-center'>
//                     <button onClick={() => enviarWapp(selectedArticulo?.Prevtapub1)} className='text-center ml-1 '>
//                         <img src={wapp} alt="icono de wapp" className='w-10 h-10 ' />
//                     </button>
//                     <button onClick={() => enviarWapp(selectedArticulo?.Prevtapub2)} className='text-center '>
//                         <img src={wapp} alt="icono de wapp" className='w-10 h-10 ' />
//                     </button>
//                     <button onClick={() => enviarWapp(selectedArticulo?.Prevtapub3)} className='text-center mr-2 '>
//                         <img src={wapp} alt="icono de wapp" className='w-10 h-10 ' />
//                     </button>
//                 </div>

//                 <hr className='mt-2 mb-1 border border-gray-300' />

//                 <p className='text-red-600 font-semibold text-center'>PRECIO MAYORISTA</p>
//                 <div className='flex justify-between items-center'>
//                     <p className='font-black'>PvtaM1</p>
//                     <p className='font-black'>PvtaM2</p>
//                     <p className='font-black'>PvtaM3</p>
//                 </div>
//                 <div className='flex justify-between items-center '>
//                     <p> {parseFloat(selectedArticulo?.Prevtamay1).toFixed(2)}</p>
//                     <p> {parseFloat(selectedArticulo?.Prevtamay2).toFixed(2)}</p>
//                     <p> {parseFloat(selectedArticulo?.Prevtamay3).toFixed(2)}</p>
//                 </div>
//                 <div className='flex justify-between items-center mb-11'>
//                     <button onClick={() => enviarWapp(selectedArticulo?.Prevtamay1)} className='text-center ml-1 '>
//                         <img src={wapp} alt="icono de wapp" className='w-10 h-10 ' />
//                     </button>
//                     <button onClick={() => enviarWapp(selectedArticulo?.Prevtamay2)} className='text-center '>
//                         <img src={wapp} alt="icono de wapp" className='w-10 h-10 ' />
//                     </button>
//                     <button onClick={() => enviarWapp(selectedArticulo?.Prevtamay3)} className='text-center mr-2 '>
//                         <img src={wapp} alt="icono de wapp" className='w-10 h-10 ' />
//                     </button>
//                 </div>

//                 <div className="absolute bottom-0 left-0 w-full">
//                     <button
//                         className="px-4 py-2 bg-red-500 text-white font-semibold w-full text-xl"
//                         onClick={closeModal}
//                     >
//                         CERRAR
//                     </button>
//                 </div>
//             </div>
//         </div>

//     )
// }

import eeuu from '../../images/eeuu.png';
import arg from '../../images/argentina.png';
import iconoStock from '../../images/stockDisponible.png';
import wapp from '../../images/wapp.png';

export default function ModalArticulo({ selectedArticulo, closeModal }) {
    const admin = localStorage.getItem('admin');

    const enviarWapp = (precio) => {
        const message = `BICICLETERIA LA FRANCESA\nArtículo: ${selectedArticulo?.Descripcion?.trim()}.\nPrecio: $${parseFloat(precio)?.toFixed(2)}`;
        const encodedMessage = encodeURIComponent(message);
        const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;

        window.open(whatsappUrl, "_blank");
    };

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-80 flex justify-center items-center z-50">
            <div className="bg-white p-6 m-1 rounded-sm shadow-lg w-full lg:max-w-lg border border-black relative">
                <div className="flex justify-center items-center mb-0 -mt-5">
                    <p className="text-center text-md font-semibold">
                        Precio actualizado al: {selectedArticulo?.fupcosto}
                    </p>
                </div>
                <div className="flex justify-center items-center">
                    <img
                        src={selectedArticulo?.Monedadolar?.trim() == '1' ? eeuu : arg}
                        alt="bandera"
                        className="w-6 h-6 mr-4"
                    />
                    <h2 className="text-lg text-center font-bold mr-4">{selectedArticulo?.Codigo}</h2>
                    {parseFloat(selectedArticulo?.Stockactual) > 0 && (
                        <img src={iconoStock} alt="stock" className="w-7 h-6" />
                    )}
                </div>

                <h2 className="text-lg text-center font-bold text-blue-700">
                    {selectedArticulo?.Descripcion?.trim()}
                </h2>

                {admin === '1' ? (
                    <>
                        {parseFloat(selectedArticulo?.Stockactual) > 0 ? (
                            <>
                                <hr className="mb-0.5 border border-gray-300" />
                                <p className="text-center font-semibold">
                                    STOCK: {selectedArticulo?.Stockactual}
                                </p>
                                <hr className="mt-0.5 border border-gray-300" />
                            </>
                        ) : (
                            <hr className="mb-1 border border-gray-300" />
                        )}

                  
                        <p className="text-center text-red-600 font-semibold">PRECIO PUBLICO</p>
                        <div className="grid grid-cols-3 gap-4 text-center">
                            <p className="font-black">PvtaP1</p>
                            <p className="font-black">PvtaP2</p>
                            <p className="font-black">PvtaP3</p>
                        </div>
                        <div className="grid grid-cols-3 gap-4 text-center">
                            <p>{parseFloat(selectedArticulo?.Prevtapub1)}</p>
                            <p>{parseFloat(selectedArticulo?.Prevtapub2)}</p>
                            <p>{parseFloat(selectedArticulo?.Prevtapub3)}</p>
                        </div>
                        <div className="grid grid-cols-3 gap-4 text-center">
                            <button onClick={() => enviarWapp(selectedArticulo?.Prevtapub1)}>
                                <img src={wapp} alt="icono de wapp" className="w-10 h-10 mx-auto" />
                            </button>
                            <button onClick={() => enviarWapp(selectedArticulo?.Prevtapub2)}>
                                <img src={wapp} alt="icono de wapp" className="w-10 h-10 mx-auto" />
                            </button>
                            <button onClick={() => enviarWapp(selectedArticulo?.Prevtapub3)}>
                                <img src={wapp} alt="icono de wapp" className="w-10 h-10 mx-auto" />
                            </button>
                        </div>

                        <hr className="mt-2 mb-1 border border-gray-300" />

                        <p className="text-red-600 font-semibold text-center">PRECIO MAYORISTA</p>
                        <div className="grid grid-cols-3 gap-4 text-center">
                            <p className="font-black">PvtaM1</p>
                            <p className="font-black">PvtaM2</p>
                            <p className="font-black">PvtaM3</p>
                        </div>
                        <div className="grid grid-cols-3 gap-4 text-center">
                            <p>{parseFloat(selectedArticulo?.Prevtamay1)}</p>
                            <p>{parseFloat(selectedArticulo?.Prevtamay2)}</p>
                            <p>{parseFloat(selectedArticulo?.Prevtamay3)}</p>
                        </div>
                        <div className="grid grid-cols-3 gap-4 text-center mb-11">
                            <button onClick={() => enviarWapp(selectedArticulo?.Prevtamay1)}>
                                <img src={wapp} alt="icono de wapp" className="w-10 h-10 mx-auto" />
                            </button>
                            <button onClick={() => enviarWapp(selectedArticulo?.Prevtamay2)}>
                                <img src={wapp} alt="icono de wapp" className="w-10 h-10 mx-auto" />
                            </button>
                            <button onClick={() => enviarWapp(selectedArticulo?.Prevtamay3)}>
                                <img src={wapp} alt="icono de wapp" className="w-10 h-10 mx-auto" />
                            </button>
                        </div>


                    </>
                ) : (
                    <>
                        <hr className='border-gray-300 mb-1' />
                        <p className="text-center text-red-600 font-semibold">PRECIO PUBLICO</p>
                        <div className="flex justify-center items-center">
                            <p className="font-black">PvtaP1</p>
                        </div>
                        <div className="flex justify-center items-center mb-10">
                            <p>{parseFloat(selectedArticulo?.Prevtapub1)}</p>
                        </div>
                    </>
                )}

                <div className="absolute bottom-0 left-0 w-full">
                    <button
                        className="px-4 py-2 bg-red-500 text-white font-semibold w-full text-xl"
                        onClick={closeModal}
                    >
                        CERRAR
                    </button>
                </div>
            </div>
        </div>
    );
}
