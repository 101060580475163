import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MensajeToken from '../MensajeToken/MensajeToken';
import InputBusquedaArticulo from '../InputBusquedaArticulo/InputBusquedaArticulo';
import CardArticulo from '../CardArticulo/CardArticulo';
import ModalArticulo from '../ModalArticulo/ModalArticulo';
import Paginacion from '../Paginacion/Paginacion';


export default function Articulos({ logout }) {

    const navigate = useNavigate();

    const [articulos, setArticulos] = useState([]);
    const [mensajeError, setMensajeError] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [totalArticulos, setTotalArticulos] = useState(0);
    const [itemsPerPage] = useState(6);
    const [showModal, setShowModal] = useState(false);
    const [selectedArticulo, setSelectedArticulo] = useState(null);
    const [toggleMenu, setToggleMenu] = useState(false);


    useEffect(() => {
        fetchData();
    }, [page, searchTerm]);

    const fetchData = async () => {
        const token = localStorage.getItem('auth_usuario');
        const admin = localStorage.getItem('admin');
        if (!token || !admin) {
            navigate('/login');
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/articulo`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    limit: itemsPerPage,
                    offset: (page - 1) * itemsPerPage,
                    searchTerm, // Enviar el término de búsqueda al backend
                }
            });

            setArticulos(response?.data.articulos);
            setTotalArticulos(response?.data.total);
            setMensajeError('');

        } catch (error) {

            setMensajeError(error.response?.data?.message || 'Ocurrió un error al obtener los artículos.');
            setToggleMenu(!toggleMenu)
            setTimeout(() => {
                localStorage.removeItem('auth_usuario');
                localStorage.removeItem('admin');
                navigate('/login');
            }, 10000);

        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setPage(1);  // Reiniciar a la primera página al cambiar el término de búsqueda
    };

    const handlePreviousPage = () => {
        if (page > 1) setPage(page - 1);
    };

    const handleNextPage = () => {
        if (page < Math.ceil(totalArticulos / itemsPerPage)) setPage(page + 1);
    };

    const openModal = (articulo) => {
        setSelectedArticulo(articulo);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedArticulo(null);
    };


    const clearInput = () => {
        setSearchTerm('')
    }


    return (
        <div className='lg:container lg:mx-auto lg:flex lg:flex-col lg:min-h-screen justify-center items-center'>

            <InputBusquedaArticulo searchTerm={searchTerm} handleSearchChange={handleSearchChange} clearInput={clearInput} />


            <div className='grid grid-cols-1 gap-2 lg:grid lg:grid-cols-2 lg:gap-6 '>

                <CardArticulo articulos={articulos} openModal={openModal} />
            </div>


            {
                totalArticulos === 0 && (
                    <div className='flex justify-center items-center mt-6'>
                        <p className='text-center text-lg font-bold text-red-500'>¡NO SE ENCUENTRA EL ARTICULO!</p>
                    </div>
                )
            }

            {totalArticulos > 5 && (
                <Paginacion articulos={articulos} page={page} totalArticulos={totalArticulos} handlePreviousPage={handlePreviousPage} handleNextPage={handleNextPage} itemsPerPage={itemsPerPage} />
            )}

            {/* Modal para mostrar detalles del artículo */}
            {showModal && selectedArticulo && (
                <ModalArticulo selectedArticulo={selectedArticulo} closeModal={closeModal} />
            )}

            {toggleMenu && <MensajeToken mensajeError={mensajeError} logout={logout} />}

        </div>
    );
}
