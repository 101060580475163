// import eeuu from '../../images/eeuu.png'
// import arg from '../../images/argentina.png'
// import iconoStock from '../../images/stockDisponible.png'


// export default function CardArticulo({ articulos, openModal }) {

//     const admin = localStorage.getItem('admin')

//     return (
//         <>

//             {articulos?.map((art, index) => (
//                 <div
//                     key={index}
//                     className='flex justify-center items-center flex-col bg-lime-100  lg:rounded-md  mt-1.5 mb-1.5 m-1   cursor-pointer transition-transform hover:scale-95 '
//                     onClick={() => openModal(art)}  // Abrir el modal al hacer clic en un artículo
//                 >
//                     <p className='text-center text-sm font-semibold'>Precio actualizado al: {art?.fupcosto}</p>
//                     <div className='flex justify-center items-center -mb-1'>
//                         <img src={art?.Monedadolar.trim() == '1' ? eeuu : arg} alt="bandera" className='w-6 h-6 mr-4' />
//                         <h5 className=' text-center font-bold mr-4'>{art?.Codigo}</h5>
//                         {art?.Stockactual?.trim() !== '0' && (
//                             <img src={iconoStock} alt="stock" className='w-7 h-6' />
//                         )}
//                     </div>

//                     <h5 className='text-md text-center text-blue-700 font-bold px-0 lg:px-2'>{art?.Descripcion?.trim()}</h5>


//                     <div className='flex justify-between  lg:justify-center  w-full  px-1 py-1 lg:px-2 lg:py-2'>

//                         <div className='self-start mr-0 ml-4 lg:mr-4'>
//                             <p className='text-red-600 font-semibold text-md '>PRECIO PUBLICO</p>
//                             <p ><strong className=' font-black'>PvtaP1 :</strong> {(parseFloat(art?.Prevtapub1) + ((parseFloat(art?.Prevtapub1) * (parseFloat(art?.Alicuota) / 100)))).toFixed(2)}</p>
//                             <p><strong className=' font-black'>PvtaP2 :</strong> {parseFloat(art?.Prevtapub2)?.toFixed(2)}</p>
//                             <p><strong className=' font-black'>PvtaP3 :</strong> {parseFloat(art?.Prevtapub3)?.toFixed(2)}</p>
//                         </div>

//                         <div className='self-end mr-4'>
//                             <p className='text-red-600 font-semibold text-md '>PRECIO MAYORISTA</p>
//                             <p><strong className=' font-black'>PvtaM1 :</strong> {parseFloat(art?.Prevtamay1)?.toFixed(2)}</p>
//                             <p><strong className=' font-black'>PvtaM2 :</strong> {parseFloat(art?.Prevtamay2)?.toFixed(2)}</p>
//                             <p><strong className=' font-black'>PvtaM3 :</strong> {parseFloat(art?.Prevtamay3)?.toFixed(2)}</p>
//                         </div>
//                     </div>
//                 </div>

//             ))
//             }
//         </>
//     )
// }


import eeuu from '../../images/eeuu.png';
import arg from '../../images/argentina.png';
import iconoStock from '../../images/stockDisponible.png';

export default function CardArticulo({ articulos, openModal }) {
    const admin = localStorage.getItem('admin');

    return (
        <>
            {articulos?.map((art, index) => (
                <div
                    key={index}
                    className='flex justify-center items-center flex-col bg-lime-100 lg:rounded-sm mt-1.5 mb-1.5 m-1 cursor-pointer transition-transform hover:scale-95'
                    onClick={() => openModal(art)} // Abrir el modal al hacer clic en un artículo
                >
                    <p className='text-center text-sm font-semibold'>Precio actualizado al: {art?.fupcosto}</p>
                    <div className='flex justify-center items-center -mb-1'>
                        <img
                            src={art?.Monedadolar.trim() == '1' ? eeuu : arg}
                            alt="bandera"
                            className='w-6 h-6 mr-4'
                        />
                        <h5 className='text-center font-bold mr-4'>{art?.Codigo}</h5>
                        {parseFloat(art?.Stockactual) > 0 && (
                            <img src={iconoStock} alt="stock" className='w-7 h-6' />
                        )}
                    </div>

                    <h5 className='text-md text-center text-blue-700 font-bold px-0 lg:px-2'>
                        {art?.Descripcion?.trim()}
                    </h5>

                    <div className={`flex ${admin === '1' ? 'justify-between' : 'justify-center'} w-full px-1 py-1 lg:px-2 lg:py-2`}>

                        <div className='self-start mr-0 ml-4 lg:mr-4'>
                            <p className='text-red-600 font-semibold text-md'>PRECIO PUBLICO</p>
                            <p>
                                <strong className='font-black'>PvtaP1 :</strong>{' '}
                                {/* {(parseFloat(art?.Prevtapub1) + (parseFloat(art?.Prevtapub1) * (parseFloat(art?.Alicuota) / 100))).toFixed(2)} */}
                                {parseFloat(art?.Prevtapub1)}
                            </p>
                            {/* Mostrar PvtaP2 y PvtaP3 solo si admin es 1 */}
                            {admin === '1' && (
                                <>
                                    <p>
                                        <strong className='font-black'>PvtaP2 :</strong>{' '}
                                        {parseFloat(art?.Prevtapub2)}
                                    </p>
                                    <p>
                                        <strong className='font-black'>PvtaP3 :</strong>{' '}
                                        {parseFloat(art?.Prevtapub3)}
                                    </p>
                                </>
                            )}
                        </div>

                        {/* Mostrar precios mayoristas solo si admin es 1 */}
                        {admin === '1' && (
                            <div className='self-end mr-4'>
                                <p className='text-red-600 font-semibold text-md'>PRECIO MAYORISTA</p>
                                <p>
                                    <strong className='font-black'>PvtaM1 :</strong>{' '}
                                    {parseFloat(art?.Prevtamay1)}
                                </p>
                                <p>
                                    <strong className='font-black'>PvtaM2 :</strong>{' '}
                                    {parseFloat(art?.Prevtamay2)}
                                </p>
                                <p>
                                    <strong className='font-black'>PvtaM3 :</strong>{' '}
                                    {parseFloat(art?.Prevtamay3)}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </>
    );
}
